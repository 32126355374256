import React from "react"

export const OperationShoulder = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="66"
      height="69"
      viewBox="0 0 66 69"
      fill="none"
    >
      <g clipPath="url(#clip0_505_1944)">
        <path
          d="M0.822266 68.1812L6.30308 26.8828C6.30308 26.8828 3.42565 14.0391 8.73109 9.1647C10.7864 7.28154 14.0475 7.29792 16.0206 9.26841C16.0206 9.26841 27.9633 16.61 18.3554 25.5182C18.3554 25.5182 24.0555 28.2528 29.8268 25.3817"
          stroke="#21A1AD"
          strokeWidth="1.7716"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.7529 68.1813C10.7529 68.1813 12.5945 28.0837 18.3548 25.5237"
          stroke="#21A1AD"
          strokeWidth="1.7716"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M52.9442 25.1905C51.6507 26.8444 51.0369 28.9241 51.2287 31.0092C51.7384 36.6205 48.2088 56.1617 47.4415 59.1638C46.9427 61.1343 46.0932 63.0065 44.8436 64.6113C41.5825 68.8034 34.9891 72.2531 27.2337 48.6563C16.1515 14.9505 8.73047 9.17005 8.73047 9.17005C11.164 7.77269 12.1341 6.88297 15.9651 7.29781C16.2776 8.4277 17.3573 9.22463 18.585 9.1373C20.3553 9.01176 22.8491 8.77704 25.3976 8.32945C25.9512 8.36766 26.5102 8.39495 27.0802 8.41133C31.2073 8.54233 32.868 10.1908 33.4161 12.0248C33.9039 13.6514 35.9372 14.2027 37.1595 13.0073C38.5626 11.6318 39.9218 10.3054 41.0234 9.23555C42.5855 7.71265 44.7065 6.89934 46.8934 6.98122H46.9318L48.4554 7.04126C49.8037 7.09585 51.0369 7.60894 51.996 8.44954C52.9497 9.28468 53.6238 10.4419 53.8431 11.7683C54.1116 13.3621 54.2432 14.9778 54.2432 16.5826C54.2432 19.4919 53.8102 22.3958 52.9442 25.1905Z"
          stroke="#21A1AD"
          strokeWidth="1.7716"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M51.262 26.2059C51.262 26.2059 42.0159 19.2628 38.3438 21.7191"
          stroke="#21A1AD"
          strokeWidth="1.7716"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M65.1778 2.75651C65.1778 3.61894 64.5968 4.38312 63.7637 4.62329C55.7179 6.93766 47.5625 6.98133 46.9322 6.98133H46.8938C46.8938 6.98133 37.4175 4.40495 31.4872 6.73024C29.6731 7.4453 27.5136 7.95839 25.398 8.32956C22.8494 8.77715 20.3557 9.01187 18.5854 9.13741C17.3577 9.22474 16.2779 8.42781 15.9655 7.29792C15.9107 7.12871 15.8833 6.94858 15.8724 6.76299C15.7902 5.32197 16.9247 4.1102 18.3771 4.08836C19.6212 4.07199 21.2216 4.0829 22.9919 4.19207C27.0806 4.4377 31.4872 2.35804 31.4872 2.35804C31.4872 2.35804 37.0009 -0.14192 46.1703 1.80128C52.539 3.14951 59.2311 1.82311 62.7224 0.884265C62.8923 0.840597 63.0677 0.818764 63.2321 0.818764C64.2735 0.818764 65.1778 1.65936 65.1778 2.75651Z"
          stroke="#21A1AD"
          strokeWidth="1.7716"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.5591 15.8076C13.5591 15.8076 10.9502 17.467 10.7529 23.7005"
          stroke="#21A1AD"
          strokeWidth="1.7716"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_505_1944">
          <rect width="66" height="69" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
