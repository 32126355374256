import React from "react"

export const OperationHip = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="70"
      viewBox="0 0 46 70"
      fill="none"
    >
      <g clipPath="url(#clip0_505_1963)">
        <path
          d="M37.4539 41.8784C35.4076 38.7991 31.7608 34.7622 28.6204 38.5097C23.9065 44.1104 30.9774 47.6306 30.9774 47.6306V69H40.5"
          stroke="#21A1AD"
          strokeWidth="1.7716"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M35.5078 39.612C35.5078 39.612 40.4607 33.4833 44.4258 40.2531C46.3606 43.5621 43.799 45.9957 40.6787 47.6295V69.09"
          stroke="#21A1AD"
          strokeWidth="1.7716"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.899414 49.7321L3.4474 48.1879C3.4474 48.1879 7.18081 55.5781 14.4841 55.0611C21.7874 54.544 23.6542 45.9543 25.0099 39.9429C25.807 36.4063 28.4912 33.9384 30.5623 32.5044C32.2247 31.3532 33.3488 29.5608 33.6008 27.5409L33.7235 26.5551C33.9006 25.1487 34.4456 23.8182 35.304 22.6945C38.8126 18.0894 47.4512 5.04629 35.5084 1.80619C23.5656 -1.43391 16.964 5.51507 15.4039 7.7142C14.5659 8.88616 13.5985 9.96849 12.4539 10.8371C10.451 12.3538 7.26938 13.9462 2.94325 13.6636"
          stroke="#21A1AD"
          strokeWidth="1.7716"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.23814 46.7609L13.3803 48.8428C13.9867 49.1462 14.7224 48.8911 15.0154 48.2706L16.9843 44.1137C17.4476 43.1347 16.521 42.0731 15.4991 42.4178L9.39484 44.4997C8.37292 44.8513 8.27072 46.2783 9.24495 46.7609H9.23814Z"
          stroke="#21A1AD"
          strokeWidth="1.7716"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.18162 39.5982C3.18162 39.5982 7.05129 39.7636 11.8543 37.0682C16.6573 34.3727 17.8359 34.2555 18.0676 32.9525C18.2311 32.0494 18.926 27.2651 19.3075 24.5834C19.4301 23.7355 18.7216 22.9909 17.8768 23.1012C16.1532 23.3356 14.3887 23.2184 12.7127 22.7221C10.1784 21.9707 6.45857 21.2537 3.1748 22.1568"
          stroke="#21A1AD"
          strokeWidth="1.7716"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_505_1963">
          <rect width="46" height="70" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
