import React, { useState, useRef, Fragment } from "react"
import { Link } from "gatsby"
import {
  IconLogo,
  IconArrow,
  IconDropdownArrow,
  IconBurgerMenu,
  IconCross,
} from "./Icons"
import { Services } from "./services"
import "../styles/navbar.scss"
import { ButtonWithIcon } from "./buttonWithIcon"
import { StaticImage } from "gatsby-plugin-image"
// Navbar is translated here

const LANGUAGES = [
  { title: "PL", link: "/" },
  { title: "ENG", link: "/en/" },
]
const SERVICES = [
  {
    name: "Orthopaedics consultations",
    link: "/en/surgical-procedures",
  },
  { name: "USG of muscles and joints", link: "/en/usg-of-muscles-and-joints" },
  {
    name: "Injection and blocks",
    link: "/en/injection-and-blocks",
    children: [
      {
        name: "PRP Injections",
        link: "/en/prp",
      },
    ],
  },
  { name: "Surgical procedures", link: "/en/surgical-procedures" },
]

const SIDE_MENU_SERVICES = [
  {
    name: "Services",
    children: [
      {
        name: "Orthopaedics consultations",
        link: "/en/orthopeadics-consultations",
        bold: true,
      },
      {
        name: "USG of muscles and joints",
        link: "/en/usg-of-muscles-and-joints",
        bold: true,
      },
      {
        name: "Injection and blocks",
        link: "/en/injection-and-blocks",
        bold: true,
      },
      {
        name: "PRP Injections",
        link: "/en/prp",
      },
      { name: "Surgical procedures", link: "/en/surgical-procedures" },
    ],
  },
  {
    name: "About me",
    link: "/en/about-me",
  },
  {
    name: "Case Registry",
    link: "/en/cases",
  },
  { name: "News", link: "/en/news" },
  {
    name: "Contact",
    link: "/en/contact",
  },
]

export const NavbarEng = () => {
  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false)
  const [servicesDropdownOpen, setServicesDropdownOpen] = useState(false)
  const [sideMenuServicesDropdownOpen, setsideMenuServicesDropdownOpen] =
    useState(false)
  const [sideMenuOpen, setSideMenuOpen] = useState(false)
  const languageMenuRef = useRef(null)
  const languageButtonRef = useRef(null)

  const handleClickOutside = event => {
    if (
      languageMenuRef.current &&
      !languageMenuRef.current.contains(event.target) &&
      !languageButtonRef.current.contains(event.target)
    ) {
      setLanguageDropdownOpen(false)
    }
  }

  const switchLanguageDropdown = () => {
    setLanguageDropdownOpen(open => {
      if (!open) {
        document.addEventListener("click", handleClickOutside, true)
      } else {
        document.removeEventListener("click", handleClickOutside, true)
      }
      return !open
    })
  }

  const switchServiceDropdown = () => {
    setServicesDropdownOpen(open => !open)
  }

  const switchSideMenuServiceDropdown = () => {
    setsideMenuServicesDropdownOpen(open => !open)
  }

  const closeServiceDropdown = () => {
    setServicesDropdownOpen(false)
  }

  const openServiceDropdown = () => {
    setServicesDropdownOpen(true)
  }

  const switchSideMenuDrawer = () => {
    setSideMenuOpen(open => {
      if (!open) {
        document.body.style.overflow = "hidden"
      } else {
        document.body.style.overflow = "inherit"
      }
      return !open
    })
  }
  const closeSideMenu = () => {
    setSideMenuOpen(false)
    document.body.style.overflow = "inherit"
  }
  const closeSideMenuOnBackClick = event => {
    if (event.target === event.currentTarget) {
      setSideMenuOpen(open => !open)
      document.body.style.overflow = "inherit"
    }
  }

  return (
    <>
      <nav className="Navbar__wrapper">
        <div className="Navbar__main">
          <div className="Navbar__buttons">
            <div className="Navbar__icon">
              <Link to={"/en"}>
                <IconLogo height={30} width={205} />
              </Link>
            </div>
            <ul className="Navbar__list">
              <li className="Navbar__list--element">
                <div
                  className="Navbar__services-button"
                  onClick={switchServiceDropdown}
                  onMouseEnter={openServiceDropdown}
                >
                  Services
                  <IconArrow
                    className={`Navbar__down-arrow-icon  ${
                      servicesDropdownOpen
                        ? "Navbar__down-arrow-icon--open"
                        : ""
                    }`}
                  ></IconArrow>
                </div>
              </li>
              <li className="Navbar__list--element">
                <Link activeClassName="active" to="/en/about-me">
                  About me
                </Link>
              </li>
              <li className="Navbar__list--element">
                <Link activeClassName="active" to="/en/cases">
                  Case Registry
                </Link>
              </li>
              <li className="Navbar__list--element">
                <Link activeClassName="active" to="/en/news">
                  News
                </Link>
              </li>
              <li className="Navbar__list--element">
                <Link activeClassName="active" to="/en/contact">
                  Contact
                </Link>
              </li>
            </ul>
            <div className="Navbar__burger-wrapper">
              <div className="Navbar__burger" onClick={switchSideMenuDrawer}>
                <IconBurgerMenu className="Navbar__test"></IconBurgerMenu>
              </div>
              <div
                className={`Navbar__side-menu-wrapper ${
                  sideMenuOpen ? "Navbar__side-menu-wrapper--open" : ""
                }`}
                onClick={closeSideMenuOnBackClick}
              >
                <div className="Navbar__side-menu">
                  <div className="Navbar__side-menu-content-wrapper">
                    <div className="Navbar__side-menu-header">
                      <IconLogo height={25} width={170} />

                      <div
                        className="Navbar__side-menu-header-cross"
                        onClick={switchSideMenuDrawer}
                      >
                        <IconCross></IconCross>
                      </div>
                    </div>
                    <ul className="Navbar__side-menu-content">
                      {SIDE_MENU_SERVICES.map(service => {
                        if (!service.link) {
                          return (
                            <Fragment key={service.name}>
                              <li
                                key={service.name}
                                className="Navbar__side-menu-tile Navbar__side-menu-tile--dropdown  Navbar__side-menu-tile--underlined"
                                onClick={switchSideMenuServiceDropdown}
                              >
                                {service.name}
                                <IconArrow
                                  className={`Navbar__down-arrow-icon Navbar__down-arrow-icon--side-menu ${
                                    sideMenuServicesDropdownOpen
                                      ? "Navbar__down-arrow-icon--open"
                                      : ""
                                  }`}
                                ></IconArrow>
                              </li>
                              {service.children.map(child => (
                                <li
                                  key={child.name}
                                  className={`Navbar__side-menu-tile sub-item ${
                                    child.bold ? "bold" : ""
                                  } ${
                                    !sideMenuServicesDropdownOpen
                                      ? "Navbar__side-menu-tile--closed"
                                      : ""
                                  }`}
                                >
                                  <Link onClick={closeSideMenu} to={child.link}>
                                    {child.name}
                                  </Link>
                                </li>
                              ))}
                            </Fragment>
                          )
                        }

                        return (
                          <li
                            key={service.link}
                            className="Navbar__side-menu-tile Navbar__side-menu-tile--underlined"
                          >
                            <Link onClick={closeSideMenu} to={service.link}>
                              {service.name}
                            </Link>
                          </li>
                        )
                      })}
                      <li className="Navbar__side-menu--lang">
                        <Link onClick={closeSideMenu} to={"/en/"}>
                          <StaticImage
                            src="../../images/uk-flag.png"
                            alt="background"
                            quality={100}
                            width={40}
                            layout="fixed"
                          />
                        </Link>
                        <Link onClick={closeSideMenu} to={"/"}>
                          <StaticImage
                            src="../../images/pl-flag.png"
                            alt="background"
                            quality={100}
                            width={40}
                            layout="fixed"
                          />
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="Navbar__side-menu-footer">
                    <ButtonWithIcon
                      newTab={true}
                      link={
                        "https://mydr.pl/patient/new-visit/stationary?kind=Prywatna&doctor={%22id%22:54669,%22name%22:%22Marek+Kulczyk%22,%22search_id%22:%22b85c4a3fdb73f5%22}&search=true&location={%22place_name%22:%22Mielec%22,%22state_name%22:%22Subcarpathian+Voivodeship%22}"
                      }
                      className="Navbar__side-menu-footer-button"
                    >
                      <IconArrow className="Navbar__right-arrow-icon"></IconArrow>
                      Book an appointment
                    </ButtonWithIcon>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Navbar__tools">
            <div className="Navbar__language">
              <button
                className="Navbar__language-button"
                onClick={switchLanguageDropdown}
                ref={languageButtonRef}
              >
                EN
                <IconDropdownArrow
                  className={`Navbar__dropdown-icon  ${
                    languageDropdownOpen ? "Navbar__dropdown-icon--open" : ""
                  }`}
                ></IconDropdownArrow>
              </button>
              <ul
                className={`Navbar__language-dropdown
            ${languageDropdownOpen ? "Navbar__language-dropdown--open" : ""}`}
                ref={languageMenuRef}
              >
                {LANGUAGES.map(language => (
                  <li
                    key={language.title}
                    className="Navbar__language-dropdown-element"
                  >
                    <Link
                      to={language.link}
                      className="Navbar__language-button-link"
                    >
                      {language.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <ButtonWithIcon
              newTab={true}
              link={
                "https://mydr.pl/patient/new-visit/stationary?kind=Prywatna&doctor={%22id%22:54669,%22name%22:%22Marek+Kulczyk%22,%22search_id%22:%22b85c4a3fdb73f5%22}&search=true&location={%22place_name%22:%22Mielec%22,%22state_name%22:%22Subcarpathian+Voivodeship%22}"
              }
            >
              <IconArrow className="Navbar__right-arrow-icon"></IconArrow>
              Book an appointment
            </ButtonWithIcon>
          </div>
        </div>
        <div
          className={`Navbar__services-dropdown  ${
            servicesDropdownOpen ? "Navbar__services-dropdown--open" : ""
          }`}
        >
          <Services
            handleClose={closeServiceDropdown}
            data={SERVICES}
          ></Services>
        </div>
      </nav>
    </>
  )
}
