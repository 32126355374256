import React from "react"

export const IconCross = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
    >
      <path
        d="M14.684 13.1686C14.8852 13.3698 14.9982 13.6426 14.9982 13.9272C14.9982 14.2117 14.8852 14.4846 14.684 14.6858C14.4828 14.887 14.2099 15 13.9253 15C13.6408 15 13.3679 14.887 13.1667 14.6858L7.5 9.01766L1.83154 14.684C1.63034 14.8852 1.35744 14.9982 1.07289 14.9982C0.788345 14.9982 0.51545 14.8852 0.314243 14.684C0.113037 14.4828 2.99821e-09 14.2099 0 13.9254C-2.99821e-09 13.6409 0.113037 13.368 0.314243 13.1668L5.9827 7.50045L0.316028 1.83233C0.114822 1.63113 0.0017851 1.35825 0.0017851 1.07372C0.0017851 0.78919 0.114822 0.516312 0.316028 0.315117C0.517235 0.113922 0.790129 0.00089233 1.07468 0.000892327C1.35923 0.000892324 1.63212 0.113922 1.83333 0.315117L7.5 5.98324L13.1685 0.314224C13.3697 0.11303 13.6426 -4.74031e-09 13.9271 0C14.2117 4.74031e-09 14.4845 0.11303 14.6858 0.314224C14.887 0.515419 15 0.788297 15 1.07283C15 1.35736 14.887 1.63024 14.6858 1.83143L9.0173 7.50045L14.684 13.1686Z"
        fill="#848484"
      />
    </svg>
  )
}
