import React from "react"

export const IconArrow = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={className}
    >
      <path d="M11.6095 7.05731L8.7475 4.19531L7.80483 5.13798L10.0002 7.33331L3.3335 7.33331L3.3335 8.66665H10.0002L7.80483 10.862L8.7475 11.8046L11.6095 8.94265C11.8595 8.69261 11.9999 8.35353 11.9999 7.99998C11.9999 7.64643 11.8595 7.30735 11.6095 7.05731Z" />
    </svg>
  )
}
