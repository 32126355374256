import React from "react"

export const IconAccessibilityReset = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M18.3938 10.511C18.394 12.5836 17.578 14.5728 16.1224 16.0482C14.6669 17.5236 12.6889 18.3664 10.6165 18.3943H10.5106C8.49727 18.3993 6.55934 17.6288 5.09907 16.2428C5.03636 16.1835 4.98595 16.1124 4.95071 16.0336C4.91548 15.9548 4.8961 15.8699 4.8937 15.7836C4.8913 15.6973 4.90592 15.6114 4.93672 15.5308C4.96751 15.4501 5.01389 15.3764 5.0732 15.3136C5.13251 15.2509 5.20359 15.2005 5.28239 15.1653C5.36118 15.1301 5.44614 15.1107 5.53242 15.1083C5.6187 15.1059 5.70461 15.1205 5.78524 15.1513C5.86587 15.1821 5.93965 15.2285 6.00236 15.2878C6.94161 16.1736 8.12104 16.763 9.39332 16.9823C10.6656 17.2017 11.9743 17.0413 13.156 16.5213C14.3376 16.0012 15.3399 15.1444 16.0374 14.0581C16.735 12.9717 17.097 11.7039 17.0782 10.4129C17.0595 9.12202 16.6607 7.86526 15.9318 6.79964C15.2029 5.73403 14.1762 4.90681 12.9799 4.42132C11.7836 3.93583 10.4708 3.8136 9.20545 4.0699C7.94009 4.32619 6.77831 4.94965 5.86522 5.86239C5.85851 5.86965 5.85138 5.8765 5.84387 5.88292L3.6612 7.88329H5.91203C6.08626 7.88329 6.25335 7.9525 6.37655 8.0757C6.49975 8.1989 6.56896 8.366 6.56896 8.54023C6.56896 8.71446 6.49975 8.88155 6.37655 9.00475C6.25335 9.12795 6.08626 9.19716 5.91203 9.19716H1.97041C1.79618 9.19716 1.62909 9.12795 1.50589 9.00475C1.38269 8.88155 1.31348 8.71446 1.31348 8.54023V4.59861C1.31348 4.42438 1.38269 4.25729 1.50589 4.13409C1.62909 4.01089 1.79618 3.94167 1.97041 3.94167C2.14464 3.94167 2.31174 4.01089 2.43494 4.13409C2.55814 4.25729 2.62735 4.42438 2.62735 4.59861V7.0457L4.94715 4.92708C6.05079 3.82773 7.45539 3.07999 8.98364 2.77826C10.5119 2.47654 12.0953 2.63434 13.5339 3.23176C14.9725 3.82918 16.2019 4.83943 17.0669 6.13497C17.9318 7.43052 18.3936 8.95328 18.3938 10.511Z"
        fill="#15445E"
      />
    </svg>
  )
}
