import React from "react"

export const OperationFeet = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="69"
      viewBox="0 0 64 69"
      fill="none"
    >
      <g clipPath="url(#clip0_505_1951)">
        <path
          d="M26.6427 7.02701C26.6427 7.02701 37.1603 33.7707 32.4471 38.4128C27.7338 43.055 21.5 50.5001 21.5 50.5001C21.5 50.5001 14.5756 58.1352 10.8635 58.4181C7.1514 58.7009 6.00965 64.3303 8.72061 64.7518C11.4316 65.1733 13.4282 63.3431 16.9997 62.5001C20.5712 61.657 25.1635 61.9175 32.7283 59.1056C40.2931 56.2937 56.4239 55.0236 56.4239 55.0236C56.4239 55.0236 61.9752 54.0475 61.5928 47.0427C61.4521 44.497 60.5185 42.0567 59.0168 39.9769C57.5151 37.897 55.2991 34.7302 53.1393 31.2361C49.5734 25.4681 47.0031 0.837471 47.0031 0.837471"
          stroke="#21A1AD"
          strokeWidth="1.7716"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.9365 6.02315L37.0761 20.2657L41.3337 21.6967L44.579 18.2747L40.9963 3.9267"
          stroke="#21A1AD"
          strokeWidth="1.7716"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.7514 25.6732L42.1159 26.555L44.5794 24.3477C44.5794 24.3477 46.9304 38.3684 50.1757 40.5758C53.421 42.7832 54.8777 43.5541 54.9902 45.8724C55.1027 48.1907 55.2152 52.1617 51.8574 51.2799C48.4996 50.3981 44.5794 49.6216 44.5794 49.6216C44.5794 49.6216 43.6851 52.5999 40.3274 52.8217C36.9696 53.0436 28.2406 56.5765 27.5656 56.5765C26.8907 56.5765 21.4069 58.1239 22.1943 55.4728C22.9818 52.8217 38.3139 44.6578 38.7638 41.1248C39.2138 37.5919 41.2273 33.9481 37.757 25.6732H37.7514Z"
          stroke="#21A1AD"
          strokeWidth="1.7716"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M55.0293 12.2626L59.8382 8.81842"
          stroke="#21A1AD"
          strokeWidth="1.7716"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M57.29 24.3255L63.1563 25.2462"
          stroke="#21A1AD"
          strokeWidth="1.7716"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M57.335 18.2248L63.1056 16.8216"
          stroke="#21A1AD"
          strokeWidth="1.7716"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.2867 19.3617L19.5273 17.8975"
          stroke="#21A1AD"
          strokeWidth="1.7716"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.6661 31.408L22.5479 34.3862"
          stroke="#21A1AD"
          strokeWidth="1.7716"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.3543 25.7508L19.46 26.5273"
          stroke="#21A1AD"
          strokeWidth="1.7716"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_505_1951">
          <rect width="64" height="69" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
