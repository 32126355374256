import React from "react"

export const IconPagginationArrow = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="15"
      viewBox="0 0 9 15"
      fill="none"
      className={className}
    >
      <path
        d="M7.29031 1.25793L2 7.20953L7.29031 13.1611"
        stroke="#15445E"
        strokeWidth="2.64516"
      />
    </svg>
  )
}
