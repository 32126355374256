import React from "react"
import { IconConsultation } from "./consultation"
import { IconIniection } from "./iniection"
import { IconSurgery } from "./surgery"
import { IconUSG } from "./usg"

export const ServiceTileIcon = ({ type }) => {
  switch (type) {
    case "consultation":
      return <IconConsultation />
    case "iniection":
      return <IconIniection />
    case "surgery":
      return <IconSurgery />
    case "usg":
      return <IconUSG />
    default:
      return null
  }
}
