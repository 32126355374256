import React from "react"

export const IconLogo = ({ width = 268, height = 39 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 268 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M60.0138 33.0226V4.33081H64.6425V17.2796L69.7513 17.1547L77.6113 4.33081H82.7729L73.8037 18.6959L83.3011 33.0226H77.9954L69.7993 20.7364L64.6377 20.8612V33.0226H60.009H60.0138Z"
        fill="#15445E"
      />
      <path
        d="M94.0563 33.4406C91.9677 33.4406 90.3688 33.0709 89.2596 32.3363C88.1505 31.6017 87.3871 30.4687 86.9693 28.9419C86.5516 27.4151 86.3403 25.4418 86.3403 23.0268V12.157H90.8249V22.9836C90.8249 24.7072 90.9209 26.0804 91.113 27.1078C91.3051 28.1353 91.7324 28.8699 92.3902 29.3164C93.048 29.7581 94.0371 29.9837 95.3575 29.9837C96.6779 29.9837 97.8255 29.8301 98.901 29.5276C99.9766 29.2203 100.855 28.9323 101.527 28.6538V12.1618H105.964V33.0277H101.527V31.6113C100.5 32.1107 99.3956 32.5428 98.224 32.9028C97.0477 33.2629 95.66 33.4454 94.0515 33.4454L94.0563 33.4406Z"
        fill="#15445E"
      />
      <path
        d="M112.763 33.0227V3.28906H117.199V33.0227H112.763Z"
        fill="#15445E"
      />
      <path
        d="M123.95 33.0229V12.1618H128.435V13.6214C128.949 13.3429 129.583 13.0644 130.341 12.7908C131.095 12.5123 131.907 12.2674 132.776 12.061C133.645 11.8545 134.48 11.7489 135.282 11.7489C136.857 11.7489 138.124 11.9169 139.09 12.2482C140.055 12.5795 140.842 13.122 141.452 13.871C142.062 13.5637 142.825 13.2469 143.742 12.9108C144.659 12.5795 145.648 12.301 146.705 12.0802C147.766 11.8593 148.813 11.7489 149.84 11.7489C151.511 11.7489 152.884 11.9361 153.965 12.3106C155.04 12.6851 155.885 13.2805 156.495 14.1015C157.105 14.9225 157.532 15.9739 157.772 17.2654C158.012 18.557 158.132 20.1461 158.132 22.033V33.0277H153.696V22.2827C153.696 20.559 153.585 19.1859 153.36 18.1585C153.134 17.131 152.692 16.382 152.034 15.9115C151.377 15.441 150.368 15.2009 149.019 15.2009C148.246 15.2009 147.468 15.273 146.681 15.4074C145.893 15.5466 145.163 15.7291 144.486 15.9499C143.814 16.1756 143.267 16.382 142.849 16.5741C143.075 17.2414 143.228 18.0912 143.305 19.1331C143.387 20.175 143.425 21.236 143.425 22.3211V33.0229H138.941V22.4027C138.941 20.6263 138.835 19.2147 138.629 18.1729C138.417 17.131 137.976 16.3772 137.303 15.9019C136.626 15.4314 135.632 15.1913 134.312 15.1913C133.573 15.1913 132.824 15.2634 132.07 15.3978C131.316 15.537 130.625 15.7195 129.996 15.9403C129.367 16.166 128.848 16.3724 128.43 16.5645V33.0133H123.946L123.95 33.0229Z"
        fill="#15445E"
      />
      <path
        d="M173.276 33.4406C170.799 33.4406 168.849 33.0469 167.418 32.2547C165.987 31.4625 164.95 30.2766 164.307 28.697C163.664 27.1126 163.342 25.1297 163.342 22.7435C163.342 20.1317 163.769 18.024 164.619 16.4156C165.469 14.8072 166.669 13.6261 168.21 12.8771C169.752 12.1282 171.6 11.7537 173.756 11.7537C177.064 11.7537 179.566 12.5218 181.256 14.063C182.941 15.6042 183.787 18.0816 183.787 21.4952L183.546 24.1167H167.922C167.956 26.114 168.388 27.6215 169.224 28.6346C170.059 29.6476 171.572 30.1566 173.756 30.1566C174.688 30.1566 175.71 30.1373 176.82 30.0941C177.929 30.0509 179.028 30.0029 180.123 29.9501C181.213 29.8925 182.149 29.8397 182.917 29.7821L183.013 32.6964C182.212 32.8068 181.247 32.9268 180.118 33.0517C178.995 33.1765 177.828 33.2725 176.623 33.3445C175.418 33.4117 174.299 33.4502 173.271 33.4502L173.276 33.4406ZM167.922 21.2408H179.302C179.302 18.8834 178.86 17.227 177.977 16.286C177.093 15.3401 175.686 14.8696 173.756 14.8696C172.503 14.8696 171.442 15.0761 170.573 15.4938C169.704 15.9115 169.046 16.5836 168.595 17.5151C168.143 18.4465 167.918 19.6852 167.918 21.2408H167.922Z"
        fill="#15445E"
      />
      <path
        d="M196.76 33.4404C195.762 33.4404 194.816 33.3444 193.918 33.1476C193.015 32.9555 192.204 32.629 191.483 32.1681C190.758 31.712 190.134 31.0782 189.601 30.2716C189.068 29.4651 188.67 28.4472 188.396 27.2133C188.122 25.9794 187.988 24.4862 187.988 22.7386C187.988 20.2948 188.324 18.2639 189.001 16.6363C189.678 15.0135 190.754 13.794 192.232 12.973C193.711 12.152 195.69 11.7439 198.162 11.7439C198.738 11.7439 199.382 11.7727 200.092 11.8255C200.798 11.8832 201.49 11.9504 202.167 12.032C202.844 12.1136 203.372 12.1856 203.756 12.2384V3.28906H208.289V33.0227H203.852L203.804 31.688C203.319 31.9377 202.685 32.2017 201.898 32.4802C201.11 32.7587 200.265 32.9891 199.367 33.1668C198.465 33.3492 197.6 33.4356 196.765 33.4356L196.76 33.4404ZM197.581 30.0652C198.354 30.0652 199.123 29.9884 199.896 29.8347C200.669 29.6811 201.398 29.4939 202.09 29.273C202.781 29.0521 203.333 28.8553 203.751 28.6921V15.4888C203.396 15.4312 202.906 15.3784 202.282 15.3208C201.658 15.2632 201.014 15.2104 200.352 15.1528C199.694 15.0951 199.089 15.0711 198.542 15.0711C197.029 15.0711 195.843 15.364 194.974 15.945C194.105 16.5307 193.486 17.3901 193.116 18.528C192.746 19.6659 192.559 21.0678 192.559 22.7338C192.559 24.2894 192.689 25.5377 192.943 26.4787C193.202 27.4246 193.562 28.1591 194.028 28.6873C194.494 29.2154 195.032 29.5755 195.641 29.7675C196.251 29.9644 196.895 30.0604 197.572 30.0604L197.581 30.0652Z"
        fill="#15445E"
      />
      <path
        d="M214.747 7.95575V3.91797H219.231V7.95575H214.747ZM214.747 33.0275V12.1616H219.231V33.0275H214.747Z"
        fill="#15445E"
      />
      <path
        d="M234.903 33.4406C232.301 33.4406 230.256 33.0709 228.781 32.3364C227.303 31.6018 226.256 30.4159 225.646 28.7739C225.036 27.1367 224.729 24.9858 224.729 22.3211C224.729 19.6565 225.065 17.6784 225.742 16.1372C226.419 14.596 227.509 13.4774 229.022 12.786C230.529 12.0946 232.507 11.7441 234.951 11.7441C235.528 11.7441 236.229 11.7777 237.05 11.8498C237.871 11.917 238.706 12.0082 239.556 12.1186C240.406 12.2291 241.14 12.3251 241.75 12.4115L241.606 15.5755C241.059 15.5179 240.415 15.465 239.676 15.4074C238.937 15.3498 238.231 15.3018 237.554 15.2634C236.877 15.2202 236.315 15.201 235.868 15.201C234.13 15.201 232.791 15.4362 231.84 15.9116C230.889 16.3821 230.232 17.1311 229.862 18.1585C229.492 19.186 229.31 20.5735 229.31 22.3211C229.31 24.208 229.478 25.7155 229.814 26.839C230.15 27.9625 230.784 28.7691 231.72 29.254C232.651 29.7389 234.034 29.9838 235.868 29.9838C236.32 29.9838 236.886 29.9646 237.578 29.9214C238.269 29.8782 238.994 29.8302 239.748 29.7773C240.502 29.7197 241.155 29.6813 241.702 29.6525L241.846 32.8165C241.203 32.8981 240.454 32.9893 239.604 33.0853C238.749 33.1814 237.909 33.263 237.074 33.335C236.238 33.4022 235.513 33.4406 234.903 33.4406Z"
        fill="#15445E"
      />
      <path
        d="M252.405 33.4406C250.119 33.4406 248.386 32.8981 247.195 31.8178C246.004 30.7376 245.414 29.1388 245.414 27.0311C245.414 25.6147 245.702 24.4672 246.283 23.5934C246.859 22.7196 247.718 22.0571 248.861 21.6153C249.999 21.1736 251.425 20.9096 253.13 20.8232L260.121 20.2422V18.6194C260.121 17.3135 259.784 16.3917 259.107 15.8491C258.435 15.3066 257.422 15.0377 256.068 15.0377C255.233 15.0377 254.253 15.0666 253.125 15.1194C251.996 15.177 250.887 15.2442 249.797 15.3258C248.703 15.4074 247.723 15.4939 246.854 15.5755L246.662 12.7428C247.464 12.5747 248.424 12.4259 249.533 12.2867C250.642 12.1474 251.809 12.0226 253.029 11.9122C254.248 11.8018 255.377 11.7441 256.404 11.7441C258.301 11.7441 259.861 11.9794 261.081 12.4547C262.3 12.9252 263.203 13.6598 263.784 14.6633C264.36 15.6619 264.653 16.9534 264.653 18.5378V28.7835C264.716 29.3404 264.984 29.7485 265.45 30.0126C265.916 30.2767 266.502 30.4495 267.212 30.5311L267.068 33.4022C266.617 33.4022 266.166 33.3974 265.719 33.383C265.268 33.3686 264.85 33.335 264.466 33.2774C264.082 33.2198 263.712 33.1526 263.357 33.0709C262.843 32.9605 262.382 32.8021 261.984 32.5908C261.58 32.3844 261.206 32.1395 260.85 31.861C260.274 32.0867 259.516 32.3172 258.584 32.5716C257.653 32.8213 256.654 33.0277 255.593 33.1958C254.532 33.3638 253.47 33.4454 252.409 33.4454L252.405 33.4406ZM253.322 30.3583C254.123 30.3583 254.954 30.2911 255.804 30.1518C256.654 30.0126 257.47 29.8542 258.238 29.6717C259.011 29.4941 259.636 29.3308 260.121 29.1916V22.9453L253.706 23.4878C252.357 23.5694 251.391 23.8959 250.81 24.4672C250.234 25.0338 249.941 25.85 249.941 26.9014C249.941 27.9529 250.22 28.8315 250.786 29.4413C251.348 30.051 252.193 30.3583 253.317 30.3583H253.322Z"
        fill="#15445E"
      />
      <path
        d="M28.1031 19.3774C27.8918 15.5221 24.7037 12.4493 20.7856 12.4493C19.2876 12.4493 17.8951 12.9006 16.7284 13.688L15.9986 11.6811L13.8091 5.75648C13.521 4.97869 12.6807 4.43616 11.7204 4.43616H2.20868C0.979504 4.44096 0.0480149 5.30037 0.019206 6.28941L1.43564 16.3959C1.84857 18.5372 1.88698 20.7313 1.54608 22.8871L0 32.6862C0 33.7233 0.849863 34.5635 1.87738 34.5635H11.7204C12.6807 34.5635 13.521 34.0258 13.8091 33.2432L15.9986 27.3185L16.5699 25.7486C18.3801 27.0257 20.7952 27.5154 23.2728 26.6752C26.2209 25.6765 28.1079 22.7766 28.1079 19.6607V19.6319C28.1079 19.5358 28.1079 19.5022 28.1079 19.3822L28.1031 19.3774Z"
        fill="#15445E"
      />
      <path
        d="M36.6641 3.23599L31.1136 17.8075C30.8255 18.5517 30.1677 19.1183 29.361 19.3679C29.121 14.9989 25.5102 11.518 21.0737 11.518C20.6463 11.518 20.2286 11.5564 19.8205 11.6092C19.4123 11.6764 19.0138 11.7677 18.6345 11.8877C18.2552 12.0077 17.8711 12.1566 17.5206 12.3246L16.277 8.93018L18.9322 1.71882C19.3019 0.686567 20.4063 0 21.6402 0H33.9657C35.9055 0 37.2691 1.6324 36.6689 3.24079L36.6641 3.23599Z"
        fill="#82C3C9"
      />
      <path
        d="M33.9609 38.9999H21.6355C20.4015 38.9999 19.2972 38.3134 18.9274 37.2811L16.2722 30.0793L17.3189 27.2034C17.5158 27.3043 17.7175 27.4003 17.9239 27.4819C18.3129 27.6404 18.7114 27.77 19.1291 27.8708C19.7533 28.0196 20.4015 28.1013 21.0689 28.1013C21.2754 28.1013 21.4674 28.0917 21.6643 28.0725C22.082 28.0533 22.4805 27.9908 22.8695 27.9044C23.0903 27.8564 23.316 27.8036 23.5369 27.7268C25.2078 27.2082 26.6482 26.1664 27.6758 24.8125C27.743 24.7212 27.8054 24.6348 27.8726 24.5436C27.9926 24.366 28.1031 24.1931 28.2087 24.0059C28.9433 22.772 29.3611 21.3316 29.3611 19.8V19.6416C30.1773 19.8817 30.8351 20.4386 31.1136 21.1828L36.6641 35.7639C37.2691 37.3579 35.9007 38.9951 33.9609 38.9951V38.9999Z"
        fill="#82C3C9"
      />
    </svg>
  )
}
