import React from "react"

export const IconContactPhone = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icon">
        <path id="Vector" d="M13.8981 9.90387L10.9538 8.58449L10.9456 8.58074C10.7928 8.51537 10.626 8.48913 10.4605 8.50441C10.2949 8.51968 10.1358 8.57599 9.99751 8.66824C9.98122 8.679 9.96557 8.69068 9.95063 8.70324L8.42938 10.0001C7.46563 9.53199 6.47063 8.54449 6.00251 7.59324L7.30126 6.04887C7.31376 6.03324 7.32563 6.01762 7.33688 6.00074C7.42715 5.86281 7.48192 5.70469 7.49631 5.54048C7.5107 5.37626 7.48428 5.21103 7.41938 5.05949V5.05199L6.09626 2.10262C6.01047 1.90466 5.86296 1.73975 5.67575 1.63252C5.48854 1.52528 5.27166 1.48147 5.05751 1.50762C4.21061 1.61906 3.43324 2.03497 2.87059 2.67768C2.30794 3.32038 1.99847 4.14592 2.00001 5.00012C2.00001 9.96262 6.03751 14.0001 11 14.0001C11.8542 14.0016 12.6797 13.6922 13.3224 13.1295C13.9652 12.5669 14.3811 11.7895 14.4925 10.9426C14.5187 10.7285 14.475 10.5117 14.3679 10.3245C14.2607 10.1373 14.096 9.98976 13.8981 9.90387ZM11 13.0001C8.87899 12.9978 6.8455 12.1542 5.34571 10.6544C3.84592 9.15462 3.00232 7.12114 3.00001 5.00012C2.99765 4.3898 3.21754 3.79949 3.61859 3.33943C4.01964 2.87938 4.57445 2.58103 5.17938 2.50012C5.17913 2.50261 5.17913 2.50512 5.17938 2.50762L6.49188 5.44512L5.20001 6.99137C5.18689 7.00646 5.17498 7.02255 5.16438 7.03949C5.07033 7.18382 5.01515 7.34999 5.0042 7.52191C4.99325 7.69382 5.0269 7.86565 5.10188 8.02074C5.66813 9.17887 6.83501 10.337 8.00563 10.9026C8.16186 10.9769 8.33468 11.0094 8.50722 10.9969C8.67976 10.9844 8.8461 10.9274 8.99001 10.8314C9.00605 10.8206 9.02149 10.8089 9.03626 10.7964L10.5556 9.50012L13.4931 10.8157C13.4931 10.8157 13.4981 10.8157 13.5 10.8157C13.4201 11.4215 13.1222 11.9775 12.662 12.3795C12.2019 12.7815 11.611 13.0022 11 13.0001Z" fill="#05C3DD"/>
      </g>
    </svg>
  )
}


