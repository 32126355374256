import React from "react"

export const OperationKnee = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="71"
      viewBox="0 0 64 71"
      fill="none"
    >
      <path
        d="M31.9945 1C44.2138 9.38758 61.4894 22.9705 62.9999 33.5687L54.9544 70H38.8634C38.8634 70 37.2177 61.9396 34.8406 54.1905C32.4635 46.4414 33.0121 36.1145 39.4119 33.5687C39.4119 33.5687 7.7785 29.762 1.56152 13.2262"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.2051 26.5205L46.6072 34.0821C46.6072 34.0821 51.8572 33.744 52.6023 27.5502L42.2051 26.5205Z"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.3777 69.9999V49.1688C42.3777 49.1688 35.3486 45.7365 40.0347 40.2796C43.1587 36.6259 46.7826 40.5643 48.8211 43.5617"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.9307 41.2843C46.9307 41.2843 51.8131 35.3158 55.7223 41.9113C57.6286 45.1337 55.103 47.5068 52.0303 49.0942V69.9999"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.6309 2.89038L35.8034 13.3276C35.8034 13.3276 42.3818 8.90426 44.7501 15.7411C46.3448 20.3148 41.0502 21.5096 37.3902 21.7945"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.8217 21.3108C39.8217 21.3108 42.6258 28.3639 34.8518 28.4109C31.0537 28.4109 30.2294 25.1195 30.3749 21.7418L11.958 11.3972"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
