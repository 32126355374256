import React from "react"

export const IconUSG = () => {
  return (
    <svg
      width="78"
      height="74"
      viewBox="0 0 78 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46.9189 1H76.1651V72.9807H1.16699V1H12.5147"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.98975 5.59717H25.5646"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.98975 9.4978H25.5646"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.3678 5.59717H70.9426"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.3678 9.4978H70.9426"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.6854 17.2351C48.5954 24.0115 62.6059 34.9853 63.831 43.5476L57.306 72.9808H44.2562C44.2562 72.9808 42.9215 66.4687 40.9937 60.2081C39.0658 53.9476 39.5107 45.6044 44.701 43.5476C44.701 43.5476 19.0461 40.4721 14.0042 27.1128"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.4412 37.7061L50.7552 44.0505C50.7552 44.0505 54.7076 43.7668 55.2685 38.57L47.4412 37.7061Z"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.0672 72.9807V55.9978C47.0672 55.9978 41.2644 53.1995 45.1329 48.7507C47.7119 45.7719 50.7036 48.9828 52.3864 51.4264"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.0002 49.6727C51.0002 49.6727 54.9139 44.8241 58.0474 50.1821C59.5755 52.7998 57.551 54.7276 55.088 56.0171V73"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.9702 18.7952L41.6642 27.2995C41.6642 27.2995 46.9835 23.6953 48.8984 29.266C50.1879 32.9927 45.9067 33.9663 42.9473 34.1984"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.1846 33.8825C45.1846 33.8825 47.4219 39.6853 41.2193 39.724C38.189 39.724 37.5313 37.016 37.6474 34.2371L22.9534 25.7263"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
