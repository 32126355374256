import React from "react"
import "../styles/button_with_icon.scss"

export const ButtonWithIcon = ({
  children,
  secondary,
  link,
  className,
  newTab,
}) => {
  return (
    <a
      href={link}
      target={newTab ? "_blank" : "_self"}
      rel="noreferrer"
      className={`${className ? className : ""} ButtonWithIcon__wrapper ${
        secondary
          ? "ButtonWithIcon__wrapper--secondary"
          : "ButtonWithIcon__wrapper--primary"
      } `}
    >
      {children}
    </a>
  )
}
