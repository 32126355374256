import React from "react"
import "../styles/services.scss"
import { Link } from "gatsby"

export const Services = ({ data, handleClose }) => {
  return (
    <div onMouseLeave={handleClose} className="Services__wrapper">
      {data.map((element, index) => (
        <ul className="Services__section" key={index}>
          <li
            className={`Services__section-header  ${
              element.link ? "Services__section-header--link" : ""
            }`}
          >
            {element.link ? (
              <Link to={element.link}>{element.name}</Link>
            ) : (
              element.name
            )}
          </li>
          {element.children &&
            element.children.map((child, index) => (
              <li className="Services__link" key={index}>
                <Link to={child.link}>{child.name}</Link>
              </li>
            ))}
        </ul>
      ))}
    </div>
  )
}
