import React from "react"
import { IconLogo } from "./Icons/logo"
import "../styles/footer.scss"
import { Link } from "gatsby"

export const Footer = ({ privacyPolicyText, nameText }) => {
  return (
    <footer className="Footer__wrapper">
      <div className="Footer__info">
        <div className="Footer__data">
          <div className="Footer__col">
            <p className="Footer__paragraph">{nameText}</p>
            <a href="mailto:mkulczyk@gmail.com">mkulczyk@gmail.com</a>
          </div>
          <div className="Footer__col">
            <a href="https://maps.app.goo.gl/fBxwZqodYi8A2B5A8">
              Marii Skłodowskiej-Curie 8A,
              <br />
              39-300 Mielec
            </a>
          </div>
        </div>
        <div className="Footer__logo">
          <IconLogo />
        </div>
      </div>
      <div className="Footer__links">
        <div className="Footer__links-data">
          <span className="Footer__copyright">Copyright 2023 © Kulmedica</span>
          <Link className="Footer__privacy-policy" to="/polityka-prywatnosci">
            {privacyPolicyText}
          </Link>
        </div>
        <span className="Footer__implementation">
          Website design:&nbsp;
          <a href="https://dariapyziakdesign.pl">Daria Pyziak</a>
        </span>
        <span className="Footer__implementation brand">
          Brand design:&nbsp;
          <a href="https://www.behance.net/rainagerula">Raina Gerula</a>
        </span>
      </div>
    </footer>
  )
}
