import React from "react"

export const IconContactBottom = () => {
  return (
    <svg width="402" height="232" viewBox="0 0 402 232" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="54.4381" height="184.097" rx="27.2191" transform="matrix(-0.755844 -0.655217 0.656315 -0.754083 271.062 213.744)" fill="#05C3DD"/>
        <rect width="55.5884" height="184.097" rx="27.7942" transform="matrix(-0.755844 -0.655217 0.656315 -0.754083 51.1807 193.212)" fill="#15445E"/>
        <rect width="55.5884" height="234.813" rx="27.7942" transform="matrix(0.656315 -0.754083 0.755844 0.655217 93.4131 59.9033)" fill="#CFF5F5" fillOpacity="0.8"/>
    </svg>
  )
}


