exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-diagnostyka-i-planowanie-diagnostyki-w-chorobach-ukladu-ruchu-js": () => import("./../../../src/pages/diagnostyka-i-planowanie-diagnostyki-w-chorobach-ukladu-ruchu.js" /* webpackChunkName: "component---src-pages-diagnostyka-i-planowanie-diagnostyki-w-chorobach-ukladu-ruchu-js" */),
  "component---src-pages-en-about-me-js": () => import("./../../../src/pages/en/about-me.js" /* webpackChunkName: "component---src-pages-en-about-me-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-injection-and-blocks-js": () => import("./../../../src/pages/en/injection-and-blocks.js" /* webpackChunkName: "component---src-pages-en-injection-and-blocks-js" */),
  "component---src-pages-en-orthopaedics-consultations-js": () => import("./../../../src/pages/en/orthopaedics-consultations.js" /* webpackChunkName: "component---src-pages-en-orthopaedics-consultations-js" */),
  "component---src-pages-en-prp-js": () => import("./../../../src/pages/en/prp.js" /* webpackChunkName: "component---src-pages-en-prp-js" */),
  "component---src-pages-en-surgical-procedures-js": () => import("./../../../src/pages/en/surgical-procedures.js" /* webpackChunkName: "component---src-pages-en-surgical-procedures-js" */),
  "component---src-pages-en-usg-of-muscles-and-joints-js": () => import("./../../../src/pages/en/usg-of-muscles-and-joints.js" /* webpackChunkName: "component---src-pages-en-usg-of-muscles-and-joints-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-iniekcja-kwasu-hialuronowego-do-stawu-js": () => import("./../../../src/pages/iniekcja-kwasu-hialuronowego-do-stawu.js" /* webpackChunkName: "component---src-pages-iniekcja-kwasu-hialuronowego-do-stawu-js" */),
  "component---src-pages-iniekcja-prp-js": () => import("./../../../src/pages/iniekcja-prp.js" /* webpackChunkName: "component---src-pages-iniekcja-prp-js" */),
  "component---src-pages-iniekcja-sterydu-js": () => import("./../../../src/pages/iniekcja-sterydu.js" /* webpackChunkName: "component---src-pages-iniekcja-sterydu-js" */),
  "component---src-pages-iniekcje-i-blokady-js": () => import("./../../../src/pages/iniekcje-i-blokady.js" /* webpackChunkName: "component---src-pages-iniekcje-i-blokady-js" */),
  "component---src-pages-konsultacja-ortopedyczna-js": () => import("./../../../src/pages/konsultacja-ortopedyczna.js" /* webpackChunkName: "component---src-pages-konsultacja-ortopedyczna-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kontrola-przed-i-po-zabiegach-ortopedycznych-js": () => import("./../../../src/pages/kontrola-przed-i-po-zabiegach-ortopedycznych.js" /* webpackChunkName: "component---src-pages-kontrola-przed-i-po-zabiegach-ortopedycznych-js" */),
  "component---src-pages-o-mnie-js": () => import("./../../../src/pages/o-mnie.js" /* webpackChunkName: "component---src-pages-o-mnie-js" */),
  "component---src-pages-ortezy-i-aparaty-skierowania-refundowane-przez-nfz-js": () => import("./../../../src/pages/ortezy-i-aparaty-skierowania-refundowane-przez-nfz.js" /* webpackChunkName: "component---src-pages-ortezy-i-aparaty-skierowania-refundowane-przez-nfz-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-usg-miesni-i-stawow-js": () => import("./../../../src/pages/usg-miesni-i-stawow.js" /* webpackChunkName: "component---src-pages-usg-miesni-i-stawow-js" */),
  "component---src-pages-zabiegi-operacyjne-js": () => import("./../../../src/pages/zabiegi-operacyjne.js" /* webpackChunkName: "component---src-pages-zabiegi-operacyjne-js" */),
  "component---src-templates-cases-list-en-js": () => import("./../../../src/templates/cases-list-en.js" /* webpackChunkName: "component---src-templates-cases-list-en-js" */),
  "component---src-templates-cases-list-js": () => import("./../../../src/templates/cases-list.js" /* webpackChunkName: "component---src-templates-cases-list-js" */),
  "component---src-templates-news-list-en-js": () => import("./../../../src/templates/news-list-en.js" /* webpackChunkName: "component---src-templates-news-list-en-js" */),
  "component---src-templates-news-list-js": () => import("./../../../src/templates/news-list.js" /* webpackChunkName: "component---src-templates-news-list-js" */)
}

