import React from "react"

export const IconConsultation = () => {
  return (
    <svg
      width="76"
      height="76"
      viewBox="0 0 76 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.1397 26.7422H3.95658C3.17245 26.7422 2.42044 27.0537 1.86598 27.6081C1.31152 28.1626 1.00001 28.9146 1.00001 29.6987V64.061C0.999102 64.4499 1.07492 64.8351 1.22309 65.1946C1.37127 65.5541 1.58891 65.8808 1.86354 66.1561C2.13817 66.4314 2.46441 66.6498 2.82356 66.7988C3.18272 66.9478 3.56773 67.0245 3.95658 67.0245H19.4699L27.4712 74.9707V67.0245H47.9053C48.2947 67.0254 48.6805 66.9494 49.0404 66.8008C49.4004 66.6522 49.7274 66.4339 50.0028 66.1585C50.2782 65.8832 50.4964 65.5561 50.645 65.1962C50.7936 64.8362 50.8697 64.4504 50.8688 64.061V47.9481"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4004 38.7478H29.8489"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4004 46.8799H42.771"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4004 55.019H42.771"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.8018 6.12904C65.0012 3.70889 61.6126 2.06904 57.977 1.37443C54.3413 0.679818 50.5868 0.954964 47.0913 2.17221C43.5958 3.38946 40.4825 5.50585 38.0646 8.30846C35.6468 11.1111 34.0097 14.5011 33.3182 18.1373C32.6266 21.7735 32.9049 25.5277 34.125 29.0223C35.3452 32.5168 37.4642 35.6283 40.2688 38.0438C43.0734 40.4593 46.4647 42.0935 50.1015 42.7821C53.7383 43.4706 57.4924 43.1892 60.9859 41.9662L66.451 47.4382V39.0854C70.1014 36.4193 72.7978 32.6501 74.1422 28.3343"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.9776 26.253C52.9524 25.4063 53.0546 24.5606 53.2809 23.7444C53.441 23.1917 53.721 22.681 54.101 22.2489C54.4572 21.87 54.8741 21.5532 55.3346 21.3116C55.7343 21.0911 56.1685 20.8774 56.6371 20.6224C57.0965 20.4072 57.529 20.1388 57.9259 19.823C58.3304 19.4904 58.6613 19.0774 58.8976 18.6101C59.1705 18.0338 59.3005 17.4002 59.2767 16.763C59.3108 15.9299 59.0922 15.1059 58.6495 14.3992C58.25 13.7966 57.6887 13.3189 57.03 13.0208C56.3806 12.7258 55.6757 12.5731 54.9624 12.5729C53.9288 12.5698 52.9148 12.8553 52.0346 13.3971C51.1543 13.9389 50.4427 14.7155 49.9797 15.6397L49.2905 15.2537C49.6072 14.5196 50.0772 13.8617 50.6689 13.324C51.2615 12.8074 51.9435 12.4034 52.6813 12.1318C53.4102 11.8658 54.1797 11.7282 54.9556 11.7252C55.6103 11.7223 56.2607 11.8319 56.8784 12.0491C57.4898 12.2635 58.0555 12.5907 58.5461 13.0139C59.0484 13.451 59.4463 13.995 59.7109 14.6059C60.0081 15.3097 60.1537 16.0682 60.1381 16.832C60.1614 17.5129 60.0343 18.1906 59.766 18.8168C59.5392 19.3176 59.2079 19.7641 58.7943 20.1262C58.3848 20.4724 57.9388 20.7728 57.4642 21.0222L55.9962 21.8078C55.6045 22.0226 55.2371 22.2791 54.9004 22.5728C54.5369 22.9249 54.2689 23.3636 54.1217 23.8478C53.9012 24.6156 53.8035 25.4133 53.8322 26.2116L52.9776 26.253ZM52.9776 31.3529V28.8581H53.8529V31.3529H52.9776Z"
        stroke="#21A1AD"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
