import React from "react"

export const IconIniection = () => {
  return (
    <svg
      width="70"
      height="74"
      viewBox="0 0 70 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.564 32.675L28.3141 18.925L55.3653 45.9762C55.8732 46.4841 56.2761 47.0871 56.551 47.7507C56.8259 48.4144 56.9674 49.1257 56.9674 49.844C56.9674 50.5623 56.8259 51.2735 56.551 51.9371C56.2761 52.6008 55.8732 53.2038 55.3653 53.7117L49.3507 59.7263C48.8428 60.2342 48.2398 60.6371 47.5762 60.912C46.9125 61.1869 46.2013 61.3284 45.483 61.3284C44.7647 61.3284 44.0534 61.1869 43.3897 60.912C42.7261 60.6371 42.1231 60.2342 41.6152 59.7263L14.564 32.675Z"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.3067 11.622L6.8313 30.0974L11.9883 35.2544L30.4638 16.779L25.3067 11.622Z"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.49474 9.61173L15.1065 1L20.267 6.15239L1.79004 24.6294"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0294 12.3859L7.38745 19.0278L12.5445 24.1848L19.1864 17.5429L14.0294 12.3859Z"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.9368 41.0552L29.2857 34.7063"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.187 53.3052L41.5359 46.9563"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.196 47.0457L38.2799 37.9619"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.0885 61.0284L56.2327 52.8843L56.4572 53.1087C57.5379 54.1895 58.145 55.6553 58.145 57.1837C58.145 58.7121 57.5379 60.1779 56.4572 61.2587C55.3764 62.3394 53.9106 62.9465 52.3822 62.9465C50.8538 62.9465 49.388 62.3394 48.3072 61.2587L48.0828 61.0342L48.0885 61.0284Z"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.3885 72.7508L56.6755 61.0378"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
