import React from "react"

export const IconAccessibilityContrast = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M10.5109 1.97058C8.82179 1.97058 7.17064 2.47145 5.76621 3.40986C4.36179 4.34826 3.26717 5.68206 2.62079 7.24257C1.9744 8.80308 1.80528 10.5202 2.1348 12.1769C2.46433 13.8335 3.2777 15.3552 4.47206 16.5496C5.66643 17.7439 7.18814 18.5573 8.84477 18.8868C10.5014 19.2163 12.2185 19.0472 13.7791 18.4008C15.3396 17.7544 16.6734 16.6598 17.6118 15.2554C18.5502 13.851 19.051 12.1998 19.051 10.5107C19.0487 8.24649 18.1481 6.07565 16.547 4.47458C14.946 2.8735 12.7751 1.97297 10.5109 1.97058ZM11.1678 3.31484C11.6125 3.35542 12.0525 3.4379 12.4817 3.56119V17.4636C12.0525 17.5869 11.6125 17.6694 11.1678 17.7099V3.31484ZM13.7956 4.07524C14.2638 4.31496 14.7043 4.60519 15.1094 4.94075V16.0807C14.7043 16.4163 14.2638 16.7065 13.7956 16.9463V4.07524ZM3.28458 10.5107C3.28675 8.70861 3.96137 6.97215 5.17641 5.64122C6.39146 4.3103 8.05945 3.48071 9.85394 3.31484V17.7067C8.05945 17.5408 6.39146 16.7112 5.17641 15.3803C3.96137 14.0493 3.28675 12.3129 3.28458 10.5107ZM16.4233 14.6609V6.36056C17.2783 7.57558 17.7372 9.02503 17.7372 10.5107C17.7372 11.9965 17.2783 13.4459 16.4233 14.6609Z"
        fill="#15445E"
      />
    </svg>
  )
}
