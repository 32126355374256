import React from "react"
import { Footer } from "../components/footer"
import "../styles/layout.scss"
import { Navbar } from "../components/navbar"
import { NavbarEng } from "../components/navbar-eng"
import { AccessibilitySettings } from "../components/accessibilitySettings"

const MainLayout = ({ children, pageContext }) => {
  if (pageContext.layout === "en") {
    return (
      <div>
        <AccessibilitySettings />
        <NavbarEng />
        <div className="Layout__container">
          <main className="Layout__wrapper">{children}</main>
          <Footer
            privacyPolicyText={"Privacy policy"}
            nameText={"Marek Kulczyk MD."}
          />
        </div>
      </div>
    )
  } else {
    return (
      <div>
        <AccessibilitySettings />
        <Navbar />
        <div className="Layout__container">
          <main className="Layout__wrapper">{children}</main>
          <Footer
            privacyPolicyText={"Polityka prywatności"}
            nameText={"lek. Marek Kulczyk"}
          />
        </div>
      </div>
    )
  }
}

export default MainLayout
