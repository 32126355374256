import React, { useState } from "react"
import {
  IconAccessibility,
  IconAccessibilityContrast,
  IconAccessibilityLinks,
  IconAccessibilityPalette,
  IconAccessibilityReset,
} from "./Icons"
import "../styles/accessibility_settings.scss"

const OPTIONS_BUTTONS = [
  {
    text: "Tryb czarno-biały",
    action: () => {
      document.body.classList.toggle("monochromatic")
      document.body.classList.remove("high-contrast")
    },
    icon: <IconAccessibilityPalette />,
  },
  {
    text: "Tryb wysokiego kontrastu",
    action: () => {
      document.body.classList.remove("monochromatic")
      document.body.classList.toggle("high-contrast")
    },
    icon: <IconAccessibilityContrast />,
  },
  {
    text: "Podkreślanie linków",
    action: () => {
      document.body.classList.toggle("underline-links")
    },
    icon: <IconAccessibilityLinks />,
  },
]

export const AccessibilitySettings = () => {
  const [drawerOpen, setDrawerOpen] = useState(false)

  const [_, setFontSize] = useState(10)

  const FONT_BUTTONS = [
    {
      text: "A-",
      action: () => {
        setFontSize(value => {
          if (value > 9) {
            document.documentElement.style.setProperty(
              "--base-font-size",
              `${value - 1}px`
            )
            return value - 1
          }
          return value
        })
      },
    },
    {
      text: "A",
      action: () => {
        setFontSize(() => {
          document.documentElement.style.setProperty("--base-font-size", `10px`)
          return 10
        })
      },
    },
    {
      text: "A+",
      action: () => {
        setFontSize(value => {
          if (value < 20) {
            document.documentElement.style.setProperty(
              "--base-font-size",
              `${value + 1}px`
            )
            return value + 1
          }
          return value
        })
      },
    },
  ]

  const reset = () => {
    setFontSize(() => {
      document.documentElement.style.setProperty("--base-font-size", `10px`)
      return 10
    })
    document.body.classList.remove("monochromatic")
    document.body.classList.remove("high-contrast")
    document.body.classList.remove("underline-links")
  }

  const onTileClick = () => {
    setDrawerOpen(open => !open)
  }

  return (
    <div
      className={`AccessibilitySettings__wrapper ${
        drawerOpen ? "AccessibilitySettings__wrapper--open" : ""
      }`}
    >
      <div className={`AccessibilitySettings__container`}>
        <div className="AccessibilitySettings__container-header">
          Narzędzia dostępności
        </div>
        {FONT_BUTTONS.map((button, index) => (
          <button
            className="AccessibilitySettings__container-font-button"
            onClick={button.action}
            key={index}
          >
            {button.text}
          </button>
        ))}
        <div className="AccessibilitySettings__container-buttons">
          {OPTIONS_BUTTONS.map(({ text, action, icon }, index) => {
            return (
              <button
                className="AccessibilitySettings__container-button"
                onClick={action}
                key={index}
              >
                <span className="AccessibilitySettings__container-button-icon">
                  {icon}
                </span>
                {text}
              </button>
            )
          })}
        </div>
        <hr className="solid AccessibilitySettings__container-buttons-divider"></hr>
        <button
          className="AccessibilitySettings__container-button AccessibilitySettings__container-button--reset"
          onClick={reset}
        >
          <span className="AccessibilitySettings__container-button-icon">
            <IconAccessibilityReset />
          </span>
          Reset
        </button>
      </div>
      <div className="AccessibilitySettings__open-button" onClick={onTileClick}>
        <IconAccessibility className="AccessibilitySettings__icon"></IconAccessibility>
      </div>
    </div>
  )
}
