import React from "react"

export const IconSurgery = () => {
  return (
    <svg
      width="90"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.7541 35.3362H24.7611V27.3433H16.993V35.3362H9V43.0966H16.993V51.0973H24.7611V43.0966H32.7541V35.3362Z"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.794 18.0324C47.1447 18.0324 49.0502 16.1269 49.0502 13.7762C49.0502 11.4256 47.1447 9.52002 44.794 9.52002C42.4434 9.52002 40.5378 11.4256 40.5378 13.7762C40.5378 16.1269 42.4434 18.0324 44.794 18.0324Z"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.794 65.0832C47.1447 65.0832 49.0502 63.1776 49.0502 60.827C49.0502 58.4764 47.1447 56.5708 44.794 56.5708C42.4434 56.5708 40.5378 58.4764 40.5378 60.827C40.5378 63.1776 42.4434 65.0832 44.794 65.0832Z"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.9636 63.3854C34.7443 62.5504 34.6349 61.6904 34.638 60.827C34.638 58.8168 35.2341 56.8518 36.3509 55.1804C37.4677 53.509 39.0551 52.2063 40.9122 51.437C42.7694 50.6677 44.813 50.4665 46.7846 50.8586C48.7561 51.2508 50.5671 52.2188 51.9885 53.6402C53.4099 55.0617 54.378 56.8726 54.7701 58.8442C55.1623 60.8158 54.961 62.8594 54.1917 64.7165C53.4225 66.5737 52.1198 68.1611 50.4484 69.2779C48.777 70.3947 46.8119 70.9908 44.8017 70.9908"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.3609 41.6082C56.4855 39.4541 56.2492 37.0934 56.6803 34.8085C57.1113 32.5236 58.1915 30.4113 59.7916 28.7242C61.3916 27.0371 63.4438 25.8467 65.7027 25.2954C67.9616 24.744 70.3314 24.855 72.5289 25.6152C74.7263 26.3754 76.6582 27.7525 78.0934 29.5819C79.5287 31.4112 80.4066 33.6153 80.6221 35.9305C80.8376 38.2456 80.3816 40.5739 79.3086 42.6367C78.2356 44.6995 76.591 46.4095 74.5717 47.5622"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.5714 41.3059C70.922 41.3059 72.8276 39.4003 72.8276 37.0497C72.8276 34.699 70.922 32.7935 68.5714 32.7935C66.2207 32.7935 64.3152 34.699 64.3152 37.0497C64.3152 39.4003 66.2207 41.3059 68.5714 41.3059Z"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.0254 8.86882V13.7763C32.9699 16.8975 34.1565 19.9129 36.3243 22.1592C38.4921 24.4054 41.4634 25.6986 44.5846 25.7541C47.7058 25.8096 50.7212 24.6229 52.9675 22.4552C55.2138 20.2874 56.5069 17.3161 56.5624 14.1949V2H33.0254"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.9653 58.5865L64.881 48.5779"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.9958 51.0975L57.3455 41.6548"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.5227 25.5447L56.3221 36.2511"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.8416 14.3965L67.2378 24.7075"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.1589 68.8665V82.1002H38.5996V68.8665"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.8794 82.1001V87.9999"
        stroke="#21A1AD"
        strokeWidth="1.7716"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
