import React from "react"

export const IconPagginationDots = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="4"
      viewBox="0 0 19 4"
      fill="none"
    >
      <path
        d="M2.22311 3.16053C1.86777 3.16053 1.56402 3.03445 1.31185 2.78227C1.05968 2.5301 0.933594 2.22635 0.933594 1.87102C0.933594 1.50422 1.05968 1.19474 1.31185 0.94257C1.56402 0.678936 1.86777 0.547119 2.22311 0.547119C2.5899 0.547119 2.89938 0.678936 3.15156 0.94257C3.41519 1.19474 3.54701 1.50422 3.54701 1.87102C3.54701 2.22635 3.41519 2.5301 3.15156 2.78227C2.89938 3.03445 2.5899 3.16053 2.22311 3.16053Z"
        fill="#15445E"
      />
      <path
        d="M9.55856 3.16053C9.20322 3.16053 8.89947 3.03445 8.6473 2.78227C8.39513 2.5301 8.26904 2.22635 8.26904 1.87102C8.26904 1.50422 8.39513 1.19474 8.6473 0.94257C8.89947 0.678936 9.20322 0.547119 9.55856 0.547119C9.92535 0.547119 10.2348 0.678936 10.487 0.94257C10.7506 1.19474 10.8825 1.50422 10.8825 1.87102C10.8825 2.22635 10.7506 2.5301 10.487 2.78227C10.2348 3.03445 9.92535 3.16053 9.55856 3.16053Z"
        fill="#15445E"
      />
      <path
        d="M16.894 3.16053C16.5387 3.16053 16.2349 3.03445 15.9827 2.78227C15.7306 2.5301 15.6045 2.22635 15.6045 1.87102C15.6045 1.50422 15.7306 1.19474 15.9827 0.94257C16.2349 0.678936 16.5387 0.547119 16.894 0.547119C17.2608 0.547119 17.5703 0.678936 17.8225 0.94257C18.0861 1.19474 18.2179 1.50422 18.2179 1.87102C18.2179 2.22635 18.0861 2.5301 17.8225 2.78227C17.5703 3.03445 17.2608 3.16053 16.894 3.16053Z"
        fill="#15445E"
      />
    </svg>
  )
}
